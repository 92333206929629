<div
	#inputEditable
	class="edit-inline__showMode"
	spellcheck="false"
	[contentEditable]="!disabled"
	[ngClass]="{ 'edit-inline__showMode--editable': disabled }"
	[attr.placeholder]="getPlaceholder"
	(click)="onTouched()"
	(blur)="onSubmit()"
	(keyup)="onKeyUp($event)"
	(keypress)="onKeyPress($event)"
    (paste)="onPaste($event)"
></div>
